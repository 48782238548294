<template>
  <div class="main">
    <h1>Create order dashboard</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
